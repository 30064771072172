import axios, { AxiosResponse } from "axios";

import { SettingsData } from "./../../types/src/settings.types";
import { ContactFormType } from "./../../types/src/contact-form.types";

export const WEB_URL = process.env.WEB_URL ?? process.env.NEXT_PUBLIC_WEB_URL;
export const API_URL = process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL;

export const api = axios.create({ baseURL: API_URL });

export const apiContact = axios.create({
  baseURL: "/api",
});

export const fetcher = async (url: string) => {
  return await api.get(url).then((res) => res.data);
};

export const fetcherWithAuthorization = async (url: string, token: string) => {
  return await api
    .get(url, { headers: { Authorization: "Bearer " + token } })
    .then((res) => res.data);
};

export const setToken = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const users = "/users";

export const urls = {
  login: "/auth/login",
  users,
  forgotPassword: users + "/forgotPassword",
  resetPassword: users + "/resetPassword",
  validateResetPassToken: users + "/validate-reset-pass-token",
  customersCheckout: users + "/customers/checkout",
  customersPaymentMethod: users + "/customers/payment-method",
  userChangeEmail: users + "/changeEmail",
  userPayments: users + "/payments",
  userAddress: users + "/address",
  startChangeEmail: "auth/startChangeEmail",
  changeEmailConfirmation: "auth/changeEmailConfirmation",
  mandates: "/mandates",
  boardMembers: "/board-members",
  medias: "/medias",
  themes: "/themes",
  authors: "/authors",
  tags: "/tags",
  categories: "/categories",
  doubts: "/doubts",
  news: "/news",
  file: "/news/file",
  notifications: "/notifications",
  reports: "/reports",
  properties: "/properties",
  advertisings: "/advertisings",
  calculateAnnuity: users + "/customers/calculate-annuity",
};

export function submitContact(values: ContactFormType) {
  return apiContact.post("/contact", values).then(() => {});
}

export interface SendMailParams {
  from: string;
  to: string;
  replyTo?: string[];
  message: string;
  subject?: string;
}

export interface SendMailSmtpParams {
  from: string;
  to: string;
  subject?: string;
  message: string;
  user: string;
  pass: string;
}

export const sendMailApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_SEND_MAIL_URL,
});

async function fetchContactEmail(): Promise<string> {
  try {
    const settingsData: SettingsData = await fetcher("/settings");
    return settingsData.data[0].email;
  } catch (error) {
    console.error("Erro ao buscar o e-mail de contato:", error);
    throw new Error("Não foi possível obter o e-mail de contato.");
  }
}

function getMessage(values: ContactFormType): string {
  return `
    Olá!
    <br>
    Uma nova solicitação de contato foi realizada pelo site.
    <br>
    <b>Nome:</b> ${values.name} <br>
    <b>Email:</b> ${values.email} <br>
    <b>Mensagem:</b> ${values.message}
  `;
}

// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export async function sendMail(
  values: ContactFormType
): Promise<AxiosResponse<any>> {
  const contactEmail = await fetchContactEmail();
  const message = getMessage(values);

  const data: SendMailParams = {
    from: process.env.NEXT_PUBLIC_CONTACT_FROM as string,
    to: contactEmail,
    replyTo: [values.email],
    message,
    subject: `Contato pelo site da Aprosoja RS - ${values.subject}`,
  };

  return sendMailApi.post("v1/email/send", data);
}
