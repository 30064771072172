"use client";
import usePagination from "./use-pagination";
import { Button } from "../index";
import {
  PaginationRoot,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from "./ui/pagination";

export type PaginationProps = {
  onPageChange?: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
};

export const Pagination = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const renderPaginationItem = () => {
    return paginationRange?.map((pageNumber, index) => {
      if (pageNumber === "...") {
        return (
          <PaginationItem key={index}>
            <Button
              className="bg-transparent text-th-color-neutral-700 font-medium rounded-lg"
              disabled
            >
              ...
            </Button>
          </PaginationItem>
        );
      }

      return (
        <PaginationItem key={index}>
          <PaginationLink
            isActive={pageNumber === currentPage}
            onClick={() => onPageChange && onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </PaginationLink>
        </PaginationItem>
      );
    });
  };

  return (
    <PaginationRoot className="flex flex-row justify-end">
      <PaginationContent>{renderPaginationItem()}</PaginationContent>
    </PaginationRoot>
  );
};
